$basepath: '/';

$colsSm: 4;
$colsMs: 6;
$colsMd: 8;
$colsLg: 12;
$colsXl: 12;

$colWidthSm: 66px;
$colWidthMd: 77px;
$colWidthLg: 71px;
$colWidthXl: 81px;

$gutter: 24px;

$maxWidthSiteSm: $gutter * $colsSm + $colWidthSm * $colsSm;
$maxWidthSiteMs: $gutter * $colsMs + $colWidthMd * $colsMs;
$maxWidthSiteMd: $gutter * $colsMd + $colWidthMd * $colsMd;
$maxWidthSiteLg: $gutter * $colsLg + $colWidthLg * $colsLg;
$maxWidthSiteXl: $gutter * $colsXl + $colWidthXl * $colsXl;

$maxWidthArticleSm: $maxWidthSiteSm;
$maxWidthArticleMs: $maxWidthSiteMs;
$maxWidthArticleMd: $maxWidthSiteMd;
$maxWidthArticleLg: $maxWidthSiteLg;
$maxWidthArticleXl: $maxWidthSiteXl;

$maxWidthContentSm: $maxWidthSiteSm;
$maxWidthContentMs: $maxWidthSiteMs;
$maxWidthContentMd: $maxWidthSiteMd;
$maxWidthContentLg: $maxWidthSiteLg - 4 * $colWidthLg - 2 * $gutter;
$maxWidthContentXl: $maxWidthSiteXl - 4 * $colWidthXl - 2 * $gutter;

$maxwidths: (
    article: 1175px,
    content: 890px,
);

$breakpoints: (
    residencePicker: 520px,
    sm: $maxWidthSiteSm + $gutter,
    ms: $maxWidthSiteMs + $gutter,
    md: $maxWidthSiteMd + $gutter,
    lg: $maxWidthSiteLg + $gutter,
    xl: $maxWidthSiteXl + $gutter,
);
