@import 'styles/includes.folkhem.scss';

.PageHomeFolkhem {
    position: relative;
    overflow: visible;

    &__Hero {
        @include u-wrap-folkhem(site);
        margin-top: 24px;
        margin-bottom: 64px;

        @include media(md) {
            margin-top: 24px;
            margin-bottom: 120px;
        }
    }
}
