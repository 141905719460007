@mixin media($key) {
    @if map-has-key($breakpoints, $key) {
        @media (min-width: map-get($breakpoints, $key)) {
            @content;
        }
    } @else {
        @error 'Unfortunately, no value could be retrieved from `#{$breakpoints}`. '
        + 'Please make sure it is defined in `$breakpoints` map.';
    }
}

@mixin media-max($key) {
    @if map-has-key($breakpoints, $key) {
        @media (max-width: (map-get($breakpoints, $key) - 1)) {
            @content;
        }
    } @else {
        @error 'Unfortunately, no value could be retrieved from `#{$breakpoints}`. '
        + 'Please make sure it is defined in `$breakpoints` map.';
    }
}
