@import 'variables';

@function contains($list, $var) {
    $index: index($list, $var);
    @if $index != null and $index > 0 {
        @return true;
    }
    @return false;
}

@mixin u-wrap($list: (), $default: true) {
    margin-right: auto;
    margin-left: auto;

    @if $default {
        padding-right: ($gutter / 2);
        padding-left: ($gutter / 2);

        @if contains($list, site) {
            @include media(md) {
                max-width: $maxWidthSiteMd;
            }

            @include media(lg) {
                max-width: $maxWidthSiteLg;
            }

            @include media(xl) {
                max-width: $maxWidthSiteXl;
            }
        }

        @if contains($list, article) {
            @include media(md) {
                max-width: $maxWidthArticleMd;
            }

            @include media(lg) {
                max-width: $maxWidthArticleLg;
            }

            @include media(xl) {
                max-width: $maxWidthArticleXl;
            }
        }

        @if contains($list, content) {
            @include media(md) {
                max-width: $maxWidthContentMd;
            }

            @include media(lg) {
                max-width: $maxWidthContentLg;
            }

            @include media(xl) {
                max-width: $maxWidthContentXl;
            }
        }
    } @else {
    }

    @media print {
        max-width: 100%;
        padding: 0;
    }
}

@mixin u-wrap-folkhem($list: (), $default: true) {
    margin-right: auto;
    margin-left: auto;

    @if $default {
        padding-right: ($gutter / 2);
        padding-left: ($gutter / 2);

        @if contains($list, site) {
            @include media(md) {
                max-width: $maxWidthSiteMd;
            }

            @include media(lg) {
                max-width: $maxWidthSiteLg;
                padding-right: 0;
                padding-left: 0;
            }

            @include media(xl) {
                max-width: $maxWidthSiteXl;
            }
        }

        @if contains($list, article) {
            @include media(md) {
                max-width: $maxWidthArticleMd;
            }

            @include media(lg) {
                max-width: $maxWidthArticleLg;
                padding-right: 0;
                padding-left: 0;
            }

            @include media(xl) {
                max-width: $maxWidthArticleXl;
            }
        }

        @if contains($list, content) {
            @include media(md) {
                max-width: $maxWidthContentMd;
            }

            @include media(lg) {
                max-width: $maxWidthContentLg;
                padding-right: 28px;
                padding-left: 28px;
            }

            @include media(xl) {
                max-width: $maxWidthContentXl;
            }
        }
    }

    @media print {
        max-width: 100%;
        padding: 0;
    }
}
